import { type TFunction } from 'i18next';
import { Brand, fetchBrandConfig } from 'AppBranding';
import { getProgramConfigValue } from 'Program';
import i18n from 'ReactI18N';
import { type CareerProfileIguanaObject, type CareerProfile } from './CareerProfiles.types';

export const getFeaturedWorkExperience = (cp: CareerProfile) => {
    if (!cp) return null;

    return cp.workExperiences.find(c => !!c.featured) ?? null;
};

export const getMostRecentPositionText = (cp: CareerProfile | null, t?: TFunction) => {
    if (!cp) return '';

    const workExperience = getFeaturedWorkExperience(cp);

    if (!workExperience) return '';

    let text = workExperience.jobTitle;
    if (!text) return '';

    if (workExperience.professionalOrganization?.text) {
        text = `${text}, ${workExperience.professionalOrganization.text}`;

        if (workExperience.endDate) {
            if (t) {
                text = `${t('careers.candidate_list_card.formerly')} ${text}`;
            } else {
                text = `Formerly ${text}`;
            }
        }
    }
    return text;
};

// eslint-disable-next-line max-lines-per-function
export function getPrograms(careerProfile: CareerProfile | CareerProfileIguanaObject | null) {
    if (!careerProfile) return null;

    const quanticBrandConfig = fetchBrandConfig(Brand.quantic);
    const valarBrandConfig = fetchBrandConfig(Brand.valar);
    const smartlyBrandConfig = fetchBrandConfig(Brand.smartly);

    const programsByBrand = {
        [Brand.quantic]: {
            logo: {
                imageOnly: quanticBrandConfig.appMenuProgramLogo,
                image: quanticBrandConfig.studentProfileListCardLogo,
                alt: quanticBrandConfig.brandNameShort,
            },
            programs: [],
        },
        [Brand.valar]: {
            logo: {
                imageOnly: valarBrandConfig.appMenuProgramLogo,
                image: valarBrandConfig.studentProfileListCardLogo,
                alt: valarBrandConfig.brandNameShort,
            },
            programs: [],
        },
        [Brand.smartly]: {
            logo: {
                imageOnly: smartlyBrandConfig.appMenuProgramLogo,
                image: smartlyBrandConfig.studentProfileListCardLogo,
                alt: smartlyBrandConfig.brandNameShort,
            },
            programs: [],
        },
    } as {
        [brand in Exclude<Brand, 'miyamiya'>]: {
            logo: { imageOnly: string | null; image: string; alt: string };
            programs: { title: string; startDate: number; isDegreeProgram: boolean }[];
        };
    };

    (careerProfile.programs ?? []).forEach(program => {
        const institutionBranding =
            'institution_branding' in program ? program.institution_branding : program.institutionBranding;
        const cohortTitle = 'cohort_title' in program ? program.cohort_title : program.cohortTitle;
        const programType = 'program_type' in program ? program.program_type : program.programType;
        const startDate = 'start_date' in program ? program.start_date : program.startDate;

        const isExecEd = getProgramConfigValue(programType, 'isExecEd');
        const isDegreeProgram = !!getProgramConfigValue(programType, 'isDegreeProgram');

        const classOf = cohortTitle.includes('Class') ? `, ${cohortTitle.substring(cohortTitle.indexOf('Class'))}` : '';
        const shortProgramTitle = getProgramConfigValue(programType, 'shortProgramTitle');

        const programTitle = isExecEd
            ? i18n.t('careers.candidate_list_card.exec_ed_cohort_title', {
                  cohortTitle: getProgramConfigValue(programType, 'transcriptProgramTitle'),
              })
            : `${shortProgramTitle}${classOf}`;

        const programData = {
            title: programTitle,
            startDate,
            isDegreeProgram,
        };

        programsByBrand[institutionBranding as keyof typeof programsByBrand].programs.push(programData);
    });

    // Sort the `programs` array for each brand in place by:
    // 1. Degree programs first
    // 2. Start date descending
    Object.keys(programsByBrand).forEach(brandKey => {
        const config = programsByBrand[brandKey as keyof typeof programsByBrand];

        if (config.programs.length > 0) {
            config.programs.sort((a, b) => {
                if (a.isDegreeProgram && !b.isDegreeProgram) return -1;
                if (!a.isDegreeProgram && b.isDegreeProgram) return 1;
                if (a.startDate > b.startDate) return -1;
                if (a.startDate < b.startDate) return 1;
                return 0;
            });
        }
    });

    return programsByBrand;
}
