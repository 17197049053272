import clsx from 'clsx';
import { type ReactNode } from 'react';

type ContainerProps = {
    children?: ReactNode;
    className?: string;
};

// This component is meant to wrap things at a high level in the component tree (like pages). Because of its use of
// media queries, it will only work as expected when it stretches all the way from the left to the right of the screen.
export function Container({ children, className }: ContainerProps) {
    return (
        <div className={clsx('mx-auto w-full max-w-full px-3.5 sm:w-[750px] md:w-[970px] lg:w-[1170px]', className)}>
            {children}
        </div>
    );
}
