const config = {
    build_number: '597918',
    build_timestamp: '2024-12-13T16:15:35.726Z',
    build_commit: '0db8a6bfcbbd487d0ce978b7b8e89dacfd729e8c',
};

// locally, the build number is always 4122 (which allows us to load non-English content locally)
if (Number.isNaN(parseInt(config.build_number, 10))) {
    config.build_number = '4122';
}

export default config;
