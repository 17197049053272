
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.candidate_list_card = window.Smartly.locales.modules.en.back_royal.careers.candidate_list_card || {};
window.Smartly.locales.modules.en.back_royal.careers.candidate_list_card = {...window.Smartly.locales.modules.en.back_royal.careers.candidate_list_card, ...{
                
    "new": "New",
    "new_match": "New Match",
    "new_message": "New Message",

                        
    "outstanding": "<i class=\"fa fa-star\"></i>&nbsp;Great Applicant",

                      
    "plus_n_more": "+{{num}} more",

             
    "status_very_interested": "Actively seeking a new position",
    "status_interested": "Passively seeking a new position",
    "status_neutral": "Open to connections",
    "status_not_interested": "Not actively looking",
    "fun_fact": "Fun Fact:",
    "more": "more",

           
    "experience": "Experience",
    "other_experience": "Other Experience",
    "education": "Education",
    "preferences": "Seeking",
    "skills": "Skills",
    "strengths": "Strengths",
    "position": "Position",
    "open_to_locations": "Open to {{numLocations}} locations (<span class=\"underline\">view</span>)",
    "open_flexible": "Open to most locations",
    "open_to_remote": "Open to Remote",
    "seeking": "{{roles}} roles in the {{industries}} industries",
    "seeking_singular": "{{roles}} roles in the {{industries}} industry",
    "seeking_or": " or ",
    "years": "years",
    "num_years": "{num} {num, plural, =1{year} other{years}}",
    "num_months": "{num} {num, plural, =1{month} other{months}}",
    "gpa": "GPA",
    "minor": "Minor",
    "non_formal_programs": "Non-Degree Programs",
    "test_scores": "Test Scores",
    "present": "Present",
    "resume": "Resume",
    "github": "Github",
    "formerly": "Formerly",
    "exec_ed_cohort_title": "Executive Education: {cohortTitle}",                                                

             
    "invite_to_connect": "<span>Invite to</span> Connect",
    "full_profile": "Full Profile",
    "save": "Save",
    "save_for_review": "Save for review later",
    "like": "Like",
    "hide": "Hide",
    "hide_from_browse": "Hide from all searches",
    "general_interest": "General Interest",
    "open": "Open",
    "open_in_tracker": "Open In Tracker",
    "connect_now": "Connect Now",
    "close": "Close",
    "close_connection": "Close Connection",
    "pass": "Pass",
    "pass_for_position": "Pass for this position",
    "saved_by": "Saved by",
    "connected_with": "Connected with",
    "one_click_reachout": "Reach out with one click"
}
}
    