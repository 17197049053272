import { formattedBrandName } from 'AppBranding';
import moment from 'moment-timezone';
import { getPrograms } from 'CareerProfiles';
import angularModule from './careers_module';

angularModule.factory('candidateListCardViewHelper', [
    '$injector',
    $injector => {
        const $translate = $injector.get('$translate');
        const Cohort = $injector.get('Cohort');
        const currentUser = $injector.get('$rootScope').currentUser;

        function canSendSendbirdMessageToUser(careerProfile) {
            return (
                currentUser?.messaging_enabled &&
                currentUser?.sendbird_access_token &&
                careerProfile.messaging_enabled &&
                careerProfile.has_sendbird_access_token
            );
        }

        function avatarUrl(careerProfile, isPreview) {
            return isPreview && careerProfile.pref_student_network_privacy === 'anonymous'
                ? null
                : careerProfile.sanitizedAvatarSrc;
        }

        function displayName(careerProfile, isPreview) {
            return isPreview && careerProfile.pref_student_network_privacy === 'anonymous'
                ? careerProfile.userInitials
                : careerProfile.sanitizedName;
        }

        function normalizeMinimumDuration(duration) {
            const min = 30 * 24 * 60 * 60 * 1000; // 1 month min for display
            return Math.max(min, duration);
        }

        function translatedListToStringSeparatedByOr(list) {
            return _.reduce(list, (memo, item, index, _list) => {
                if (index === _list.length - 1) {
                    return memo + $translate.instant('careers.candidate_list_card.seeking_or') + item;
                    // if list is long enough, pre-pend a comma
                }
                if (index > 0 && _list.length > 2) {
                    return `${memo}, ${item}`;
                    // else just return the item
                }
                return memo + item;
            });
        }

        function formalEducationExperiences(careerProfile, params = {}) {
            const formalEducation = _.chain(careerProfile.educationExperiences)
                .filter(e => {
                    if (params.omitInternalExperiences) {
                        return (
                            e.degreeProgram &&
                            e.orgName &&
                            !e.orgName.toLowerCase().includes('smartly') &&
                            !e.orgName.toLowerCase().includes('quantic')
                        );
                    }
                    return e.degreeProgram && e.orgName;
                })
                .map(
                    e =>
                        `${e.degreeName}, ${e.major}, ${e.orgName}${
                            params.omitGraduation ? '' : `, ${e.graduation_year}`
                        }`,
                )
                .value();

            return formalEducation;
        }

        function formalEducationExperiencesSansInternal(careerProfile) {
            return formalEducationExperiences(careerProfile, {
                omitInternalExperiences: true,
                omitGraduation: true,
            });
        }

        function informalEducationExperiences(careerProfile) {
            const informalEducation = _.chain(careerProfile.educationExperiences)
                .filter(e => !e.degreeProgram && e.orgName)
                .map(e => `${e.major}, ${e.orgName}, ${e.graduation_year}`)
                .value();

            // add non degree programs to the mix, if applicable
            if (careerProfile.completed_non_degree_program_inclusions?.length) {
                informalEducation.unshift(
                    ...careerProfile.completed_non_degree_program_inclusions.map(
                        ({ program_type, graduated_at }) =>
                            `${Cohort.standaloneShortProgramTitle(program_type)}, ${formattedBrandName(
                                'long',
                                Cohort.branding(program_type),
                            )}, ${moment.utc(graduated_at * 1000).format('YYYY')}`,
                    ),
                );
            }

            return informalEducation;
        }

        function calculateEducationExperiencesMoreNumber(formalEducation, informalEducation) {
            // we don't show any education experiences if they don't have *some* formal education
            if (formalEducation.length > 0) {
                // we only show formal education on the front of the card; if there are 3 or fewer, everything in the card will be informal
                if (formalEducation.length <= 3) {
                    return informalEducation.length;
                    // if more than 3 formal education experiences, some will be on the inside, along with any informal education experiences
                }
                return formalEducation.length + informalEducation.length - 3;
            }
            return 0;
        }

        function workplaceStrengths(careerProfile) {
            return _.map(careerProfile.top_workplace_strengths, item =>
                $translate.instant(`careers.field_options.${item}`),
            );
        }

        function workplaceAttributes(careerProfile) {
            return _.map(careerProfile.top_personal_descriptors, item => {
                // hack for an attribute that conflicts with a location...
                if (item === 'flexible') {
                    item = 'flexible_attribute';
                }
                return $translate.instant(`careers.field_options.${item}`);
            });
        }

        function seekingRoles(careerProfile) {
            return translatedListToStringSeparatedByOr(
                _.map(careerProfile.primary_areas_of_interest, item =>
                    $translate.instant(`careers.field_options.${item}`),
                ),
            );
        }

        function seekingIndustries(careerProfile) {
            return translatedListToStringSeparatedByOr(
                _.map(careerProfile.job_sectors_of_interest, item =>
                    $translate.instant(`careers.field_options.${item}`).toLowerCase(),
                ),
            );
        }

        // condense work experience into roles with a fallback to job titles when role is "other"
        function roles(careerProfile, key, expanded) {
            // group by role, converting "other" into a role based on job title
            const rolesGroups = _.chain(careerProfile[key])
                .map(experience => {
                    // for legacy data: if it's an "other" job, add each job to roles with the job title
                    if (experience.role === 'other') {
                        return {
                            role: experience.job_title, // use job title
                            start_date: experience.start_date,
                            end_date: experience.end_date,
                            duration: experience.duration,
                            is_job_title: true, // track that it was a job title, not a normal role
                        };
                    }
                    return experience;
                })
                .groupBy('role')
                .value();

            const _roles = [];

            // for each group of jobs per role...
            _.forEach(rolesGroups, (jobs, role) => {
                // capture current date
                const now = new Date();

                // don't modify them
                jobs = angular.copy(jobs);

                // sort the jobs by start date ascending
                jobs = _.chain(jobs)
                    // for simplicity, map all null end_dates to now
                    .map(j => {
                        if (!j.end_date) {
                            j.end_date = +now;
                        }
                        return j;
                    })
                    .sortBy(j => j.start_date)
                    .value();

                // helper and temp variable to track total experience time for this role
                let totalTime = 0;

                function addTime(start_date, end_date) {
                    totalTime += end_date - start_date;
                }

                // total up all of the consecutive ranges of time to arrive at a total amount of experience for role
                // be sure to account for the potential of overlapping jobs with the same role; we don't want to double count!

                // initialize these with the values fo the first job
                let previousStartDate = jobs[0].start_date;

                let previousEndDate = jobs[0].end_date;

                // iterate through the jobs in order of start date, skipping the first one...
                for (let i = 1; i < jobs.length; i++) {
                    const job = jobs[i];

                    // if the start date of this job is greater than the end date of the last job,
                    // then close out the previous job and add its time to the total
                    if (job.start_date > previousEndDate) {
                        addTime(previousStartDate, previousEndDate);

                        // start tracking this job as the new previous start and end date range
                        previousStartDate = job.start_date;
                        previousEndDate = job.end_date;

                        // otherwise, the jobs overlap; save the end date of the current job as the new
                        // previous end date and continue (essentially, merging them together)
                    } else {
                        previousEndDate = Math.max(job.end_date, previousEndDate);
                    }
                }

                // the last entry won't have its time captured, so be sure to add it
                addTime(previousStartDate, previousEndDate);

                // finally, account for minimum duration we want to display and store in an array
                _roles.push({
                    key: role,
                    duration: normalizeMinimumDuration(totalTime),
                    is_job_title: jobs[0].is_job_title,
                });
            });

            if (expanded) {
                // return object with roles a year or more in one group and roles less in another
                const yearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
                return {
                    expanded: _.chain(_roles)
                        .filter(role => role.duration >= yearInMilliseconds)
                        .sortBy(r => -r.duration)
                        .map(r => {
                            const role = r.is_job_title ? r.key : $translate.instant(`careers.field_options.${r.key}`);
                            const years = Math.floor(moment.duration(r.duration).asMonths() / 12);
                            const duration = `${years}–${years + 1} ${$translate.instant(
                                'careers.candidate_list_card.years',
                            )}`;
                            return {
                                name: role,
                                duration,
                                years,
                            };
                        })
                        .value(),
                    collapsed: _.chain(_roles)
                        .filter(role => role.duration < yearInMilliseconds)
                        .sortBy(r => -r.duration)
                        .map(r => {
                            const role = r.is_job_title ? r.key : $translate.instant(`careers.field_options.${r.key}`);
                            const duration = moment.duration(r.duration).humanize();
                            return `${role}, ${duration}`;
                        })
                        .value(),
                };
            }
            // return the sorted and translated list of roles
            return _.chain(_roles)
                .sortBy(r => -r.duration)
                .map(r => {
                    const role = r.is_job_title ? r.key : $translate.instant(`careers.field_options.${r.key}`);
                    const duration = moment.duration(r.duration).humanize();
                    return `${role}<span class="duration">, ${duration}</span>`;
                })
                .value();
        }

        // get remaining skills
        function otherSkills(careerProfile) {
            return _.map(_.drop(careerProfile.skills || [], 4), skill => skill.text);
        }

        // get all locations, filtering out none just in case
        function allLocations(careerProfile) {
            return _.chain(careerProfile.locations_of_interest)
                .filter(location => location !== 'none')
                .map(item => $translate.instant(`careers.field_options.${item}`))
                .value();
        }

        function employmentTypes(careerProfile) {
            return translatedListToStringSeparatedByOr(
                _.chain(careerProfile.employment_types_of_interest)
                    .sortBy(type => {
                        if (type === 'permanent') {
                            return 0;
                        }
                        if (type === 'part_time') {
                            return 1;
                        }
                        if (type === 'contract') {
                            return 2;
                        }
                        if (type === 'internship') {
                            return 3;
                        }
                        return 10; // shouldn't be possible
                    })
                    .map(type => $translate.instant(`careers.edit_career_profile.job_preferences_form.${type}`))
                    .value(),
            );
        }

        function authorizedToWorkInUs(careerProfile) {
            return careerProfile.authorized_to_work_in_us
                ? $translate.instant(
                      `careers.edit_career_profile.job_preferences_form.${careerProfile.authorized_to_work_in_us}`,
                  )
                : null;
        }

        return {
            getSummarizedProfileInfo(careerProfile, isPreview) {
                // Note: this logic must be defensive against missing fields!
                // In particular, see `career_profile.rb` and the possible as_json fields that can come out.
                // As a guiding principle, we should try to make this method work with a profile missing any
                // data, up to and including a blank profile, as we may one day allow preview of incomplete cards
                // in the admin that rely on this method.
                const userInfo = {
                    avatarUrl: avatarUrl(careerProfile, isPreview),
                    displayName: displayName(careerProfile, isPreview),
                    location: careerProfile.locationString,
                    authorized_to_work_in_us: authorizedToWorkInUs(careerProfile),
                    fullTimeWorkExperiences: _.without(
                        careerProfile.fullTimeWorkExperiences,
                        careerProfile.featuredWorkExperience,
                    ),
                    partTimeWorkExperiences: careerProfile.partTimeWorkExperiences,
                    roles: roles(careerProfile, 'fullTimeWorkExperiences'),
                    rolesExpanded: roles(careerProfile, 'fullTimeWorkExperiences', true),
                    formalEducationExperiences: formalEducationExperiences(careerProfile),
                    nonformalEducationExperiences: informalEducationExperiences(careerProfile),
                    formalEducationExperiencesSansInternal: formalEducationExperiencesSansInternal(careerProfile),
                    current_position: careerProfile.mostRecentPositionText,
                    otherSkills: otherSkills(careerProfile),
                    seekingRoles: seekingRoles(careerProfile),
                    seekingIndustries: seekingIndustries(careerProfile),
                    seekingIndustrySingular:
                        careerProfile.job_sectors_of_interest && careerProfile.job_sectors_of_interest.length === 1,
                    allLocations: allLocations(careerProfile),
                    employmentTypesOfInterest: employmentTypes(careerProfile),
                    workplaceStrengths: workplaceStrengths(careerProfile),
                    workplaceAttributes: workplaceAttributes(careerProfile),
                    programs: getPrograms(careerProfile),
                    canSendSendbirdMessageToUser: canSendSendbirdMessageToUser(careerProfile),
                    userId: careerProfile.user_id,
                    utcOffset: careerProfile.place_details.utc_offset,
                    timezone: careerProfile.timezone,
                };

                userInfo.moreEducationExperiencesNum = calculateEducationExperiencesMoreNumber(
                    userInfo.formalEducationExperiences,
                    userInfo.nonformalEducationExperiences,
                );
                userInfo.moreEducationExperiencesNumSansInternal = calculateEducationExperiencesMoreNumber(
                    userInfo.formalEducationExperiencesSansInternal,
                    userInfo.nonformalEducationExperiences,
                );
                userInfo.moreWorkExperiencesNum = _.without(
                    careerProfile.workExperiences,
                    careerProfile.featuredWorkExperience,
                ).length;

                return userInfo;
            },
        };
    },
]);
